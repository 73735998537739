<script setup lang="ts">
definePageMeta({
  // title: 'Welkom bij Stagedoos',
  // description: 'Welkom bij Stagedoos',
  isPublic: true,
})

const { isAuthenticated } = useAuth()
if (isAuthenticated.value) navigateTo('/dashboard')

const { appName } = useAppName()
const { tenant } = useTenant()
</script>

<template>
  <div>
    <template v-if="tenant == 'match2gether'">
      <div class="flex flex-col gap-4">
        <PageTitle>
          Welkom op {{ appName }}!
        </PageTitle>

        <BaseParagraph>
          Match2gether is het online stage-platform van het Udens College, Fioretti College en Elde College.
        </BaseParagraph>
      </div>
    </template>

    <template v-else-if="tenant == 'mas4you'">
      <div class="flex flex-col gap-4">
        <ContentMas4you />

        <div>
          <BaseButton color="primary" to="/inloggen">
            Klik hier om in te loggen
          </BaseButton>
        </div>

        <BaseHeading as="h3" class="mb-4">
          Stage aanbieden?
        </BaseHeading>

        <BaseParagraph>
          Heb je een leuke stage voor leerlingen uit de regio Noord-Veluwe? Meld je dan aan als organisatie.
        </BaseParagraph>

        <BaseButton to="/organizations/signup" class="mt-4">
          Aanmelden organisatie
        </BaseButton>
      </div>
    </template>

    <ContentPage
      v-else
      slug="welkom"
      :fail-silently="true"
    />
  </div>
</template>
