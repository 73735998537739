<template>
  <div>
    <!-- <ContentLinkBlocks /> -->

    <div class="flex flex-col gap-4">
      <PageTitle>Welkom op de website voor maatschappelijke stages in regio Noord-Veluwe!</PageTitle>

      <BaseParagraph>
        Op deze website kunnen leerlingen uit Elburg, Ermelo, Harderwijk, Nunspeet, Oldebroek, Zeewolde en Putten maatschappelijke stages zoeken.
      </BaseParagraph>

      <BaseMessage type="info">
        Mas4you wordt vernieuwd! We zijn druk bezig om deze website nóg mooier, leuker en makkelijker te maken.
      </BaseMessage>
    </div>
  </div>
</template>
